import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { Button, Image } from "@diemmea/dma-design-system";
import Fallback from "../Fallback";
import { useTranslation } from 'react-i18next';
import logo from "./../../assets/images/logoerpbridge.png"
import SignIn from "./SignIn";
import ConfirmSignup from "./ConfirmSignup";

const AuthLayout = (props: any) => {
  const { t } = useTranslation();
  return (
    <div
      className="h-full container mx-auto pt-24 pb-8 flex flex-col justify-between px-8 md:px-0"
      style={{ maxWidth: 445 }}
    >

      <div>
        <div className="flex justify-start">
          <a href="/" className="mb-8">
            <Image
              alt="Logo ERP Bridge"
              src={logo}
              classNameImage="w-72 h-16 object-contain"
              classNameWrapper="w-72 h-16 mb-4"
            />
          </a>
        </div>
        <Outlet />
      </div>
      <div className="flex flex-col justify-center items-center">
        <p className="text-xxs">{`©${new Date().getFullYear()} ERP Bridge, Inc. ${t('footer.rights_reserved')}.`}</p>
        <Button
          isLink
          onClick={() => {
            window.open("https://erpbridge.io/terms/", "_blank")
          }}
        >
          {t('footer.privacy')}
        </Button>
      </div>
    </div>
  );
};

const AuthPage = () => {
  const Login = lazy(() => import("./Login"));
  const ForgotPassword = lazy(() => import("./ForgotPassword"));
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="login" element={
          <Suspense fallback={<Fallback />}>
            <Login />
          </Suspense>
        } />
        <Route path="sign-up" element={
          <Suspense fallback={<Fallback />}>
            <SignIn />
          </Suspense>
        } />
        <Route path="confirm-sign-up" element={
          <Suspense fallback={<Fallback />}>
            <ConfirmSignup />
          </Suspense>
        } />
        <Route path="forgot-password" element={
          <Suspense fallback={<Fallback />}>
            <ForgotPassword />
          </Suspense>
        } />
        <Route index element={
          <Suspense fallback={<Fallback />}>
            <Login />
          </Suspense>
        } />
      </Route>
    </Routes>
  )
};

export default AuthPage;
