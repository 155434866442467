import React, { lazy, Suspense, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { AlertPopup, Button, Icon, Input } from "@diemmea/dma-design-system";
import Fallback from "../Fallback";
import { useTranslation } from "react-i18next";
import "./index.css";
import Navbar from "../../components/Navbar";
import { useNotificationStore } from "../../store/notification.store";
import { Dialog, DialogContent, DialogContentText, DialogTitle, makeStyles, Theme } from "@mui/material";
import tailwindMyConfig from "../../tailwind.my.config";



const PrivateLayout = (props: any) => {
  const { t } = useTranslation();
  const notificationStore = useNotificationStore();

  return (
    <div className="flex justify-between flex-col" style={{ minHeight: "100vh" }}>
      <Navbar />
      <div className="container mx-auto" style={{ minHeight: `calc(100vh - 56px - 64px)`, paddingBottom: 100 }}>
        <Outlet />
      </div>
      <div className="bg-secondaryDark h-16">
        <div className="container  mx-auto flex w-full h-full items-center md:justify-between justify-center">
          <span className="text-white text-sm md:block hidden">©{new Date().getFullYear()} ERP Bridge. All rights reserved</span>
          <span className="text-white text-sm">© Credits: <a className="text-primary" href="https://www.dma.it/" target={"_blank"}>dma.it</a></span>
        </div>
      </div>

      <Dialog
        open={notificationStore.isVisibleUpgradePopup}
        onClose={() => {
          notificationStore.hideUpgradePopup()
        }}
        className={`dialog-upgrade ${notificationStore.isVisibleScheduleMeeting && "dialog-upgrade-meeting"}`}
        BackdropProps={{ style: { filter: `blur(3px)`, backdropFilter: `blur(3px)` } }}
        id="alert-dialog-upgrade-plan"
        aria-labelledby="alert-start-dialog-upgrade-plan"
        aria-describedby="alert-start-dialog-upgrade-plan"
      >
        <DialogTitle className="relative bg-primary text-white font-bold text-xl text-center">
          <span>{"Need an upgrade?"}</span>
          <button type="button" className="absolute right-[15px] top-[15px]"
            onClick={() => {
              notificationStore.hideUpgradePopup()
            }}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 15.32L15.32 4" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15.32 15.32L4 4" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </DialogTitle>
        {
          notificationStore.isVisibleScheduleMeeting ?
            <DialogContent className="px-16 pb-10" >
              <iframe src="https://lp.diemmea.com/meetings/francesco-fabbri/erpbridge?embed=true" data-hs-ignore="true" style={{ border: "none", height: "100%", width: "100%", padding: 0, margin: 0 }}></iframe>
            </DialogContent>
            :
            <DialogContent className="px-16 pb-10">
              <DialogContentText className="text-primary  mt-8 mb-6 font-[600] text-base flex justify-center items-center ">
                <Icon icon="premium" fill={tailwindMyConfig.theme.extend.colors.primary} />
                <span className="ml-2">{`This feature is included in the ${notificationStore.planTypeUpgradePopup} plan`}</span>
              </DialogContentText>
              <DialogContentText className="mb-8 text-secondaryUltraDark font-400 text-lg text-center">
                {"Unlock more features with the upgrade"}
              </DialogContentText>

              <div className="flex items-center justify-center mt-3">
                <Button
                  isLoading={false}
                  loadingText={"..."}
                  className="w-auto mr-3" appearance="primary" onClick={async () => {
                    notificationStore.showScheduleMeeting()
                  }}>
                  {"Schedule a meeting"}
                </Button>

                <Button
                  isLoading={false}
                  loadingText={"..."}
                  className="w-auto ml-3" appearance="tertiary" onClick={() => {
                    window.open(`https://www.erpbridge.io/#form`, '_blank');
                    notificationStore.hideUpgradePopup()
                  }}>
                  {"Send an email"}
                </Button>

              </div>
            </DialogContent>
        }
      </Dialog>


    </div>
  );
};

function Private() {
  const NotFound = lazy(() => import("./../NotFound"));
  const InstallationRouter = lazy(() => import("./Installation/router"));

  return (
    <Routes>
      <Route element={<PrivateLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<Fallback />}>
              <InstallationRouter />
            </Suspense>
          }
        />

        {/* Installation */}
        <Route
          path="installations/*"
          element={
            <Suspense fallback={<Fallback />}>
              <InstallationRouter />
            </Suspense>
          }
        />

        <Route
          path="*"
          element={
            <Suspense fallback={<Fallback />}>
              <NotFound />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
}
export default Private;
