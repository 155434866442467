import create from "zustand";
import User from "../model/User.model";
import { Auth } from "aws-amplify";
import { CognitoUser, UserData } from "amazon-cognito-identity-js";
import {
  getUserLogged,
  isUserLogged,
  resetUserLoggedData,
  storeUserLoggedData,
} from "../services/storage";
import { IPayloadSignup, signup } from "../network/actions/auth.actions";

interface AuthState {
  user: User | undefined;
  login: (email: string, password: string) => Promise<any>;
  signup: (data:IPayloadSignup) => Promise<any>;
  confirmSignup: (email: string, code: string) => Promise<any>;
  resendVerificationCode: (email: string) => Promise<any>;
  logout: () => Promise<void>;
}

const getUsernameFromEmail = (email:string) : string => email

const useAuthStore = create<AuthState>((set) => ({
  user: getUserLogged(),
  login: async (email: string, password: string) => {
    // Login to Cognito AWS
    const responseSignIn: CognitoUser = await Auth.signIn(email, password);
    // Get User Attribute
    const responseUser: User = await new Promise((resolve, reject) => {
      responseSignIn.getUserData((error, userData: UserData | undefined) => {
        if (error) reject(error);
        const user = User.factoryCognitoUser(userData!);
        resolve(user);
      });
    });
    // Store data locally
    storeUserLoggedData(responseSignIn.getSignInUserSession()!, responseUser);
    set((state) => ({
        ...state,
        user: responseUser,
    }))
  },
  logout: async() => {
    resetUserLoggedData();
    set((state) => ({
      ...state,
      user: undefined,
    }))
  },
  signup: async (data:IPayloadSignup) => {
    // SignUp to Cognito AWS
    await signup(data)
  },
  resendVerificationCode: async (email: string) => {
    await Auth.resendSignUp(getUsernameFromEmail(email))
  },
  confirmSignup: async (email: string, code: string) => {
    await Auth.confirmSignUp(getUsernameFromEmail(email), code);
  },
}));

export { useAuthStore };
