import { IHsToken } from "../store/installation.store"
import logo_ms_sql from "../assets/images/MS_SQL.png"
import logo_sap_hana from "../assets/images/sap_hana_logo.jpeg"

export enum InstallationStatus {
    COMPLETED_SUCCESSFULLY = "COMPLETED_SUCCESSFULLY",
    READY = "READY",
    RUNNING = "RUNNING",
    PENDING = "PENDING",
    ERROR = "ERROR",
    IDLE = "IDLE"
}

type DatabaseColumn = {
    name: string;
    dataType: string;
}

export type DatabaseTable = {
    name: string;
    columns: DatabaseColumn[];
}
export type InstallationTier = {
    maxObjectProperties: number;
    minFrequency: number;
    name: string;
    objectsWhitelist: string[];
}
export enum EInstallationTier {
    FREE = "Free",
    BUSINESS = "Business",
    CUSTOM = "Custom"
}
type Installation = {
    id: string;
    frequency: number;
    name: string;
    createdAt: string;
    lastRunAt: string;
    state: InstallationStatus;
    hsTokens: IHsToken;
    databaseTables: DatabaseTable[];
    tier: InstallationTier;
    dbmsTech: IDbmsTechEnum;
}
export const isFreeTier = (i: InstallationTier): boolean => i?.name?.toLowerCase() === "free"
export const isCustomTier = (i: InstallationTier): boolean => i?.name?.toLowerCase()?.includes("custom") 
    || i?.name?.toLowerCase()?.includes("professional") || i?.name?.toLowerCase()?.includes("two-way")

export const isObjectInWhitelist = (objectsWhitelist: string[], value: string): boolean => {
    return !!objectsWhitelist?.includes(value) || objectsWhitelist?.includes("custom:*")
}

export enum IDbmsTechEnum {
    MS_SQL = "MS_SQL",
    SAP_HANA = "SAP_HANA"
}
export const getIconForDB = (s: IDbmsTechEnum): string => {
    switch (s) {
        case IDbmsTechEnum.MS_SQL:
            return logo_ms_sql
        case IDbmsTechEnum.SAP_HANA:
            return logo_sap_hana
        default:
            return ""
    }
}
export const getLabelForDB = (s: IDbmsTechEnum | undefined): string => {
    switch (s) {
        case IDbmsTechEnum.MS_SQL:
            return "SQL Server"
        case IDbmsTechEnum.SAP_HANA:
            return "SAP Hana"
        default:
            return ""
    }
}

export const getLabelForStatus = (s: InstallationStatus): string => {
    switch (s) {
        case InstallationStatus.COMPLETED_SUCCESSFULLY:
            return "views.installations.status.completed"
        case InstallationStatus.RUNNING:
            return "views.installations.status.running"
        case InstallationStatus.PENDING:
            return "views.installations.status.pending"
        case InstallationStatus.READY:
            return "views.installations.status.ready"
        case InstallationStatus.ERROR:
            return "views.installations.status.error"
        case InstallationStatus.IDLE:
                return "views.installations.status.idle"
        default:
            return ""
    }
}
export const getStateForStatus = (s: InstallationStatus): "success" | "error" | "waiting" | "warning" | "waiting" | undefined => {
    switch (s) {
        case InstallationStatus.COMPLETED_SUCCESSFULLY:
            return "success"
        case InstallationStatus.READY:
            return "success"
        case InstallationStatus.RUNNING:
            return "warning"
        case InstallationStatus.PENDING:
            return "waiting"
        case InstallationStatus.ERROR:
            return "error"
        case InstallationStatus.IDLE:
            return "waiting"
        default:
            return undefined
    }
}

export default Installation;