import create from "zustand";
import { EInstallationTier } from "../model/Installation.model";

interface IShowUpgrade {
    planTypeUpgradePopup: EInstallationTier | undefined;
}

interface NotificationState extends IShowUpgrade {
    isVisibleUpgradePopup: boolean;
    isVisibleScheduleMeeting: boolean;
    showUpgradePopup: (data: IShowUpgrade) => Promise<any>;
    hideUpgradePopup: () => void;
    showScheduleMeeting: () => Promise<void>;
}

const useNotificationStore = create<NotificationState>((set) => ({
    isVisibleUpgradePopup: false,
    isVisibleScheduleMeeting: false,
    planTypeUpgradePopup: undefined,
    hideUpgradePopup: () => {
        set((state) => ({
            ...state,
            isVisibleUpgradePopup: false,
            isVisibleScheduleMeeting: false
        }))
    },
    showUpgradePopup: async (data: IShowUpgrade) => {
        set((state) => ({
            ...state,
            ...data,
            isVisibleUpgradePopup: true
        }))
    },
    showScheduleMeeting: async () => {
        set((state) => ({
            ...state,
            isVisibleScheduleMeeting: true
        }))
    }
}));

export { useNotificationStore };
