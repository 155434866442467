import axios from "axios";
import NetworkClient, { getBaseUrl } from "..";
import { endpoint } from "../api";
const qs = require('qs')

export const getTokens = (code: string): Promise<any> => {

    return new Promise((resolve, reject) => {

    var data = qs.stringify({
        'grant_type': 'authorization_code',
        'client_id': '45964ef3-2b2d-4051-8e93-88a77a4970c9',
        'client_secret': '66d706ef-914c-4750-999a-76f612980ce2',
        'redirect_uri': 'http://localhost:3000/installations/add',
        'code': code
      });
      var config:any = {
        method: 'post',
        url: 'https://api.hubapi.com/oauth/v1/token',
        headers: { 
          'Accept': 'application/json, text/plain, */*', 
          'Referer': 'http://localhost:3000/', 
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        resolve(response?.data);
      })
      .catch(function (error) {
        reject(error);
        })
  })
}

export interface IPayloadSignup {
  name: string | undefined;
  email: string | undefined;
  password: string | undefined;
  companyName: string | undefined;
  privacy: boolean | undefined;
}

export const signup = (data: IPayloadSignup): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${getBaseUrl()}${endpoint.auth.signup()}`,data)
      .then((res: any) => {
        resolve(res?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};