import { FC, useState } from "react";
import Loader from "../../components/Loader";

interface IFallback{
    title?: string;
    className?: string;
    style?: any;
  }

const Fallback: FC<IFallback> = ({ title, className, style, ...props }: IFallback) => {
    return (
    <div style={style} className={`${className} flex flex-col items-center justify-center py-8 transition-all`}>
           {
               title && 
               <p className="mt-16 text-secondaryUltraDark">{title}</p>
           }
    </div>
  );
};
export default Fallback;
