import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Button, Icon, Input, Separator, StatusMessage } from "@diemmea/dma-design-system";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { DOMAIN_MAIL_EXCLUDED, TITLE_WEBSITE } from "../../../services/utils";
import { useAuthStore } from "./../../../store/auth.store"
import { toast } from "react-toastify";
import axios from "axios";
import { Helmet } from "react-helmet";

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [showPsw, setShowPsw] = useState(false);
  const [showConfirmPsw, setShowConfirmPsw] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const actLogin = useAuthStore((store) => store.login)
  const actSignUp = useAuthStore((store) => store.signup)

  const initialValues = {
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    company: "",
    privacy: false
  };

  Yup.addMethod(Yup.string, "isValidDomain", function (errorMessage) {
    return this.test(`email`, errorMessage, function (value) {
      const { path, createError } = this;

      return (
        (DOMAIN_MAIL_EXCLUDED.map(d => value?.includes(d)).filter(d => d === false).length == 0) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  const getEmailDomain = (email: string): string => email?.split("@")?.[1] || "";
  const isValidDomain = (domain: string): boolean => DOMAIN_MAIL_EXCLUDED.findIndex(v => v === domain) === -1;

  const loginSchema = Yup.object().shape({
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
    company: Yup.string().required(),
    email: Yup.string()
      .test("email", "${path} is not valid domain", value => {
        const domain = getEmailDomain(value || "");
        const isValid = isValidDomain(domain || "")
        return isValid;
      })
      .email().required(),
    password: Yup.string().required(),
    passwordConfirm: Yup.string().required().oneOf([Yup.ref('password'), null]),
    privacy: Yup.boolean().oneOf([true]).required()
  });
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    validateOnMount: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const payload = {
        name: `${values.firstname || ""} ${values.lastname || ""}`,
        companyName: values.company,
        email: values.email,
        password: values.password,
        privacy: values.privacy
      }

      const hubspotFields = Object.keys(values)?.filter(k => k !== "passwordConfirm" && k !== "password")?.map(k => {
        return ({
          "objectTypeId": "contact",
          "name": k,
          "value": (values as any)[k]
        })
      })
      try {
        const formGuid = `9d3d2ad3-4da3-42f1-b49b-b2987949e565`
        const portalId = "4334701"
        axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`, {
          "fields": hubspotFields
        })
      } catch (error) {
      }
      try {
        setLoading(true);
        const act = await actSignUp(payload)
        console.log(act)
        setSubmitting(false);
        toast("Registration completed successfully ", { type: "success" })
        navigate('/login')
      } catch (error: any) {
        toast(`${t("views.login.signup_error")}: ${t("views.login.psw_tip1")}, ${t("views.login.psw_tip2")}`, { type: "warning" })
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });
  return (
    <div>
      <Helmet>
        <title>ERP Bridge for HubSpot | Signin</title>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <h1 className="text-xxxl font-bold flex flex-col mb-8 text-black leading-title" >{`Sign-up to `}<div className="text-primary"><span className="text-black">{"to"}</span><span>{` ${TITLE_WEBSITE}`}</span></div></h1>
      <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">

        <div className="flex items-center justify-between mb-4">
          <div className="flex flex-col w-1/2 mr-3">
            <Input.LabelInput required>
              {"Name"}
            </Input.LabelInput>
            <Input.TextInput
              placeholder=""
              {...formik.getFieldProps("firstname")}
              type="text"
              name="firstname"
              autoComplete="off"
            />
          </div>

          <div className="flex flex-col w-1/2 ml-3">
            <Input.LabelInput required>
              {"Last name"}
            </Input.LabelInput>
            <Input.TextInput
              placeholder=""
              {...formik.getFieldProps("lastname")}
              type="text"
              name="lastname"
              autoComplete="off"
            />
          </div>
        </div>


        <div className="flex flex-col mb-4">
          <Input.LabelInput required>
            {"Company Name"}
          </Input.LabelInput>
          <Input.TextInput
            placeholder=""
            {...formik.getFieldProps("company")}
            type="text"
            name="company"
            autoComplete="off"
          />
        </div>


        <div className="flex flex-col mb-4">
          <Input.LabelInput required>
            {"E-mail"}
          </Input.LabelInput>
          <Input.TextInput
            placeholder=""
            {...formik.getFieldProps("email")}
            type="email"
            name="email"
            autoComplete="off"
          />
          {
            !isValidDomain(getEmailDomain(formik.getFieldProps("email")?.value)) ?
              <span className="text-error text-xxs">{`Domain ${getEmailDomain(formik.getFieldProps("email")?.value)} is not allowed`}</span>
              :
              null
          }
        </div>


        <div className="flex flex-col mb-4">
          <Input.LabelInput required>
            {"Password"}
          </Input.LabelInput>
          <Button
            isLink
            className="pb-1"
            onClick={() => setShowPsw((show) => !show)}
          >
            {showPsw ? t("views.login.nascondi_password") : t("views.login.mostra_psw")}
          </Button>
          <Input.TextInput
            type={showPsw ? "text" : "password"}
            autoComplete="off"
            {...formik.getFieldProps("password")}
          />
        </div>


        <div className="flex flex-col">
          <Input.LabelInput required>
            {"Confirm Password"}
          </Input.LabelInput>
          <Button
            isLink
            className="pb-1"
            onClick={() => setShowConfirmPsw((show) => !show)}
          >
            {showConfirmPsw ? t("views.login.nascondi_password") : t("views.login.mostra_psw")}
          </Button>
          <Input.TextInput
            type={showConfirmPsw ? "text" : "password"}
            autoComplete="off"
            {...formik.getFieldProps("passwordConfirm")}
          />


          <div className="flex mt-4">
            <Input.CheckboxInput
              id="privacy"
              checked={formik.getFieldProps("privacy")?.value}
              {...formik.getFieldProps("privacy")}
            />
            <label className="text-xxs ml-2 " htmlFor={"privacy"}>{"I have read the "}<Button className="text-xxs" isLink onClick={() => {
              window.open("https://www.erpbridge.io/terms/", "_blank")
            }}>privacy policy</Button>{" and agree to receive marketing & sales communications"}</label>
          </div>


        </div>



        <div>{formik.status ?
          <StatusMessage status="error" title={t("views.login.login_error_title")}>
            {formik.status}
          </StatusMessage> : null}</div>
        <div className="mt-4">
          <Button
            type="submit"
            appearance="primary"
            isLoading={loading}
            loadingText={`${t("views.login.login_loading")}...`}
            isDisabled={formik.isSubmitting || !formik.isValid || loading}
          >
            {"SIGN UP"}
          </Button>
        </div>
        <Separator className="my-4" />

        <div className="mb-16 flex items-center justify-center text-xs">
          {"Already registered? "}
          <Button
            type="button"
            isLink
            appearance="secondary"
            onClick={() => {
              navigate("/auth/login");
            }}
          >
            <span className="underline text-xs ml-2 font-bold">{"Back to login"}</span>
          </Button>
        </div>

      </form>
    </div>
  );
};
export default SignIn;
