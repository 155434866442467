import React, { Suspense, useEffect } from "react";
import RouterApp from "./router/AppRouter";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { getEmail } from "./services/storage";
import { Helmet } from "react-helmet";

function App() {
  useEffect(() => {
    console.log(`<< RUNNING ERPBridge with ${process.env.REACT_APP_ENV_NAME} env >>`)
  }, [])


  const app_version = require("../package.json").version;
  Bugsnag.start({
    apiKey: "add6af016d7a651fd74ea1f4ec9cb56a",
    plugins: [new BugsnagPluginReact()],
    enabledBreadcrumbTypes: [
      "error",
      "log",
      "navigation",
      "request",
      "user",
      "state",
    ],
    releaseStage: process.env.REACT_APP_ENV_NAME,
    enabledReleaseStages: ["staging", "prod"],
    appVersion: app_version,
    onError: function (event) {
      console.log("onError", event);
      event.setUser(undefined, getEmail() || undefined, app_version);
    },
  });
  const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

  return (
    <Suspense>
      <ErrorBoundary>
        <Helmet>
          <title>ERP Bridge | Admin</title>
        </Helmet>
        <RouterApp />
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
