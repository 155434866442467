import { getQueryParamsClassicModeFilters, getQueryParamsFilters, getQueryParamsSort, ListRequest } from ".";
import { LIMIT_LIST_UNLIMITED } from "../services/utils";

export const endpoint = {
  auth: {
    signup: () => `users`,
  },
  installations: {
    new: () => `installations`,
    edit: (id: string) => `installations/${id}`,
    get: (id: string) => `installations/${id}`,
    delete: (id: string) => `installations/${id}`,
    editHsToken: (installationId: string) => `hubspot/${installationId}/tokens`,
    getHsInfo: (installationId: string) => `hubspot/${installationId}/me`,
    hsProperties: (installationId: string, objectTypeId: string) =>
      `hubspot/${installationId}/properties?objectTypeId=${objectTypeId}`,
    list: (args: ListRequest): string =>
      `installations?skip=${args.skip * args.limit}&limit=${args.limit}${getQueryParamsSort(args.sort) ? `&${getQueryParamsSort(args.sort)}` : ""
      }${getQueryParamsFilters(args.filters)
        ? `&${getQueryParamsFilters(args.filters)}`
        : ""
      }`,
    objectSchema: (installationId: string) => `hubspot/${installationId}/object-schemas`

  },
  operations: {
    new: () => `operations`,
    edit: (id: string) => `operations/${id}`,
    get: (id: string) => `operations/${id}`,
    delete: (id: string) => `operations/${id}`,
    changeOrder: (id: string) => `operations/change-order/${id}`,
    list: (args: ListRequest): string =>
      `operations?skip=${args.skip * args.limit}&limit=${args.limit}${getQueryParamsSort(args.sort) ? `&${getQueryParamsSort(args.sort)}` : ""
      }${getQueryParamsFilters(args.filters)
        ? `&${getQueryParamsFilters(args.filters)}`
        : ""
      }`,
    errors: {
        list: (id_operation: string, args: ListRequest) => `operations/${id_operation}/executions?_errors[isEmpty]=false&skip=${args.skip * args.limit}&limit=${args.limit}${getQueryParamsSort(args.sort) ? `&${getQueryParamsSort(args.sort)}` : ""
          }${getQueryParamsClassicModeFilters(args.filters)
            ? `&${getQueryParamsClassicModeFilters(args.filters)}`
            : ""
          }`,
      },
  },
  installationLogs: {
    list: (id_installation: string, nextToken:string|undefined, args: ListRequest) => `installation-logs/${id_installation}?limit=${args.limit}${nextToken ? `&nextToken=${nextToken}` : ``}${getQueryParamsSort(args.sort) ? `&${getQueryParamsSort(args.sort)}` : ""
      }${getQueryParamsClassicModeFilters(args.filters)
        ? `&${getQueryParamsClassicModeFilters(args.filters)}`
        : ""
      }`,
    download: (id_installation: string, key_log: string) => `installation-logs/${id_installation}/streams/${key_log}`,
  },
};
