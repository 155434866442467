import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Button, Input, Separator, StatusMessage } from "@diemmea/dma-design-system";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { TITLE_WEBSITE } from "../../../services/utils";
import {useAuthStore} from "./../../../store/auth.store"
import { toast } from "react-toastify";

const ConfirmSignup = () => {
  const [loading, setLoading] = useState(false);
  const [showPsw, setShowPsw] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const actResendVerificationCode = useAuthStore((store)=>store.resendVerificationCode)
  const actConfirmVerificationCode = useAuthStore((store)=>store.confirmSignup)
  let location = useLocation();

  const initialValues = {
    email: (location.state as unknown as any)?.email || "",
    code: "",
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string().required(),
    code: Yup.string().required(),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    validateOnMount: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setLoading(true);
        await actConfirmVerificationCode(values?.email,values?.code)
        setSubmitting(false);
        setLoading(false);
        toast("Your account has been successfully confirmed", {type:"success"})
        navigate('/auth/login')
      } catch (error:any) {
        toast(error.message, {type:"error"})
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <div>
        <h2 className="text-black text-l mt-3 mb-6">{"Check your mailbox, we are sending you the code to confirm your registration."}</h2>
        <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
          <div className="flex flex-col mb-4">
            <label className="text-xs">{t("views.login.field_email")}</label>
            <Input.TextInput
              placeholder=""
              {...formik.getFieldProps("email")}
              type="email"
              name="email"
              autoComplete="off"
              disabled
            />
          </div>
          <div className="flex flex-col">
            <label className="text-xs">{"Code"}</label>
            <Input.TextInput
              type={"text"}
              autoComplete="off"
              {...formik.getFieldProps("code")}
            />
          </div>
          

          <div className="mt-4">
            <Button
              type="submit"
              appearance="primary"
              isLoading={loading}
              loadingText={`${t("views.login.login_loading")}...`}
              isDisabled={formik.isSubmitting || !formik.isValid || loading}
            >
              {"Confirm Sign-up"}
            </Button>
          </div>
          
          <Separator className="my-4"/>

          <div className="">
            <Button
                type="button"
                isLink
                onClick={async ()=>{
                    try {
                        await actResendVerificationCode(formik.getFieldProps("email")?.value)
                        toast("Code re-sent correctly", {type:"success"})
                    } catch (error: any) {
                        toast(error.message, {type:"error"})
                    }
                }}
              >
                {"Resend verification code"}
              </Button>
          </div>
        </form>
    </div>
  );
};
export default ConfirmSignup;
