import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AuthPage from "../../views/Auth";
import Private from "../../views/Private";
import Amplify from "aws-amplify";
import { useAuthStore } from "./../../store/auth.store"
// const Login = lazy(() => import("../../views/Login"));

// Init Cognito
Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID,
});

export default function RouterApp() {
  const is_logged = useAuthStore((store) => store.user)
  console.log("User_is_logged", is_logged)
  return (
    <Router>
      <Routes>
        {is_logged ? (
          <>
            <Route path='/*' element={<Private />} />
            <Route index element={<Navigate to='/installations' />} />
            <Route path='auth/*' element={<Navigate to='/installations' />} />
          </>
        ) : (
          <>
            <Route path='auth/*' element={<AuthPage />} />
            <Route path='*' element={<Navigate to='/auth' />} />
          </>
        )}
      </Routes>
    </Router>
  );
}
