import axios from "axios";
import { Auth } from "aws-amplify";
import { getAccessToken, getExpireTime, setAccessToken, setExpireTime, resetUserLoggedData } from "../services/storage";
import { IFilter, ISort } from "@diemmea/dma-design-system/dist/ui/Table";
const API_URL = `https://${process.env.REACT_APP_BASEURL}/api`;


export interface ListResponse<T> {
  paging: {
    total: number
    next: any
  }
  data: T[]
}
export interface ListRequest {
  skip: number
  limit: number
  sort: ISort | null | undefined
  filters: IFilter[] | null | undefined
}
export const getQueryParamsSort = (sort: ISort | null | undefined) => {
  return (sort && sort.id && sort.direction) ? `orderBy=${sort.id}&orderDirection=${sort.direction}` : ""
}
export const getQueryParamsFilters = (filters: IFilter[] | null | undefined) => {
  return (filters && filters.length) ? filters.map(f => {
    return `_${f.id}[${f.compare}]=${f.value}`
  }).join("&") : ""
}
export const getQueryParamsClassicModeFilters = (filters: IFilter[] | null | undefined) => {
  return (filters && filters.length) ? filters.map(f => {
    return `${f.id}=${f.value}`
  }).join("&") : ""
}

const isTokenExpired = (error: any) => {
  return error.response?.status == 401;
};

export const refreshToken = async () => {
  const session = await Auth.currentSession();
  const new_access_token = session.getAccessToken().getJwtToken();
  console.log(`Saved new access token: ...${new_access_token?.substring(500)}`)
  setAccessToken(new_access_token);
  setExpireTime(session?.getAccessToken()?.payload?.exp)
  return new_access_token;
};

export const getBaseUrl = (version: string = "v1") => `${API_URL}/${version}/`

const NetworkClient = (bearerToken = null, version = "v1") => {
  const getBearerToken = (token: string | null) => `Bearer ${bearerToken || token}`;
  const _networkClient = axios.create({ baseURL: getBaseUrl(version) });
  const formatResponse = (resp: any) => {
    return {
      ...resp,
      data: resp?.data
    }
  }

  _networkClient.interceptors.request.use(
    (request) => {
      request!.headers!.Authorization = getBearerToken(getAccessToken());
      return request;
    },
    (error) => {
      console.log("Error_request_general", error)
      return Promise.reject(error);
    }
  );

  _networkClient.interceptors.response.use(
    (response) => {
      return formatResponse(response)
    },
    async (error) => {
      console.log(error, error.data);
      const isTokenExpired = Math.round(new Date().getTime() / 1000) > Number(getExpireTime())
      console.log("Expire token", getExpireTime())
      if (isTokenExpired) {
        try {
          const new_access_token = await refreshToken();
          const newHeader = {
            'Content-Type': 'application/json',
            'Authorization': getBearerToken(
              new_access_token
            )
          }
          error.config.headers = newHeader;
          error.config.baseURL = getBaseUrl(version);

          try {
            const response_request_post_refresh = axios.request(error.config);
            return response_request_post_refresh.then((resp) => {
              return formatResponse(resp)
            })
          } catch (errSecondRequest) {
            return logoutAndGoHome(errSecondRequest);
          }
        } catch (errRefresh) {
          return logoutAndGoHome(errRefresh);
        }
      } else return Promise.reject(error);
    }
  );
  return _networkClient;
};

const logoutAndGoHome = (err: any) => {
  // console.log("Refresh Token Scaduta -> Force Logout", err);
  resetUserLoggedData();
  window.location.href = "/";
  return Promise.reject(err);
};

export default NetworkClient;
