import { CognitoUserSession } from 'amazon-cognito-identity-js';
import User from '../model/User.model';
// import User from '../model/User.model';

const KEY_ACCESS_TOKEN = "access_token";
const KEY_REFRESH_TOKEN = "refresh_token";
const KEY_EMAIL = "email";
const KEY_EXPIRE_TIME = "exp";

export const storeUserLoggedData = (session: CognitoUserSession, user: any) => {
  setAccessToken(session?.getAccessToken().getJwtToken());
  setRefreshToken(session?.getRefreshToken().getToken());
  setExpireTime(session?.getAccessToken()?.payload?.exp);
  setEmail(user.email);
};

export const resetUserLoggedData = (): void => {
  removeRefreshToken();
  removeAccessToken();
  removeEmail();
  removeExpireTime();
};

export const isUserLogged = (): boolean => {
  return (
    getAccessToken() != null && getRefreshToken() != null && getEmail() != null
  );
};

export const getUserLogged = (): User|undefined => {
  return isUserLogged() ? new User(getEmail() || "","","","") : undefined
};

// ACCESS TOKEN
export const setAccessToken = (access_token: string) => {
  localStorage.setItem(KEY_ACCESS_TOKEN, access_token || "");
};

export const getAccessToken = (): string | null => {
  return localStorage.getItem(KEY_ACCESS_TOKEN);
};

const removeAccessToken = (): void => {
  localStorage.removeItem(KEY_ACCESS_TOKEN);
};

// REFRESH TOKEN
const setRefreshToken = (refresh_token: string) => {
  localStorage.setItem(KEY_REFRESH_TOKEN, refresh_token || "");
};

const getRefreshToken = (): string | null => {
  return localStorage.getItem(KEY_REFRESH_TOKEN);
};

const removeRefreshToken = (): void => {
  localStorage.removeItem(KEY_REFRESH_TOKEN);
};

// EMAIL
const setEmail = (email: string) => {
  localStorage.setItem(KEY_EMAIL, email || "");
};

export const getEmail = (): string | null => {
  return localStorage.getItem(KEY_EMAIL);
};

const removeEmail = (): void => {
  localStorage.removeItem(KEY_EMAIL);
};

// EXP TIME
export const setExpireTime = (exp: string) => {
  localStorage.setItem(KEY_EXPIRE_TIME, exp || "");
};

export const getExpireTime = (): string | null => {
  return localStorage.getItem(KEY_EXPIRE_TIME);
};

const removeExpireTime = (): void => {
  localStorage.removeItem(KEY_EXPIRE_TIME);
};